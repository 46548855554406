import React, { useContext } from 'react';
import { CopyUrlButton, SocialBar } from 'wix-ui-tpa/cssVars';
import { Facebook, XCorp, LinkedIn } from '@wix/wix-ui-icons-common/on-stage';
import settingsParams from '../../settingsParams';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { st, classes } from './SocialBar.st.css';
import { faqButtonClickSrc63Evid836 } from '@wix/bi-logger-labs-data/v2';
import { SOCIAL_URLS, SocialMedia } from '../../../../constants/social';
import { DATA_HOOKS } from '../../../../constants/dataHooksElements';

const SocialWrapper: React.FC<{ questionId: string }> = ({ questionId }) => {
  const logger = useBi();
  const settings: ISettingsContextValue = useSettings();
  const { baseURL } = useContext(AppContext);
  const env = useEnvironment();
  const { t } = useTranslation();
  const { appDefinitionId } = env;
  const isEditor = env.isEditor || env.isPreview;

  // Get social settings
  const { facebook, twitter, linkedin, copyQuestionLink, show } = settings.get(
    settingsParams.social,
  );
  const showSocial = facebook || twitter || linkedin || copyQuestionLink;

  if (!show || !showSocial) {
    return null;
  }

  const socialShare = (media: SocialMedia): string => {
    const shareUrl = new URL(baseURL);
    shareUrl.searchParams.set('questionId', questionId);
    shareUrl.searchParams.set('appDefId', appDefinitionId);

    if (media === SocialMedia.Copy) {
      return shareUrl.toString();
    }

    const url = SOCIAL_URLS[media] + encodeURIComponent(shareUrl.toString());

    window.open(url, '_blank', 'top=200, left=200, width=700, height=500');

    logger.report(
      faqButtonClickSrc63Evid836({ button_name: media, action: 'link' }),
    );

    return url;
  };

  return (
    <div
      className={st(classes.socialBarWrapper)}
      data-hook={DATA_HOOKS.WIDGET_SOCIAL_WRAPPER}
    >
      <SocialBar
        className={st(classes.socialBar)}
        data-hook={DATA_HOOKS.WIDGET_SOCIAL_BAR}
      >
        {facebook && (
          <SocialBar.Icon
            tooltip={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.facebook')
            }
            icon={<Facebook />}
            onClick={() => socialShare(SocialMedia.Facebook)}
            data-hook={DATA_HOOKS.WIDGET_SOCIAL_BAR_FACEBOOK}
          />
        )}

        {twitter && (
          <SocialBar.Icon
            tooltip={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.twitter')
            }
            icon={<XCorp />}
            onClick={() => socialShare(SocialMedia.Twitter)}
            data-hook={DATA_HOOKS.WIDGET_SOCIAL_BAR_TWITTER}
          />
        )}

        {linkedin && (
          <SocialBar.Icon
            tooltip={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.linkedin')
            }
            icon={<LinkedIn />}
            onClick={() => socialShare(SocialMedia.LinkedIn)}
            data-hook={DATA_HOOKS.WIDGET_SOCIAL_BAR_LINKEDIN}
          />
        )}

        {copyQuestionLink && (
          <CopyUrlButton
            tooltipText={
              isEditor
                ? t('social.icons.disablePreviewMode')
                : t('display.tab.social.copyQuestionLink')
            }
            successText={t('display.tab.social.copiedQuestionLink')}
            url={socialShare(SocialMedia.Copy)}
            data-hook={DATA_HOOKS.WIDGET_SOCIAL_BAR_COPY}
          />
        )}
      </SocialBar>
    </div>
  );
};

export default SocialWrapper;
