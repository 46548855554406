export enum SocialMedia {
  Facebook = 'facebook',
  Twitter = 'twitter',
  LinkedIn = 'linkedin',
  Copy = 'copy',
}

export const SOCIAL_URLS: Record<SocialMedia, string> = {
  [SocialMedia.Facebook]: 'https://www.facebook.com/sharer/sharer.php?u=',
  [SocialMedia.Twitter]: 'https://twitter.com/intent/tweet?url=',
  [SocialMedia.LinkedIn]:
    'https://www.linkedin.com/shareArticle?mini=true&url=',
  [SocialMedia.Copy]: '',
};
