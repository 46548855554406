import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  useEnvironment,
  WidgetProps,
  IHostProps,
  useBi,
} from '@wix/yoshi-flow-editor';
import { faqAppLoads, faqQuestionExpanded } from '@wix/bi-logger-labs-data/v2';
import { pauseHeightChanges } from '@wix/yoshi-flow-editor/wix-height-updater';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { st, classes } from './Widget.st.css';

import HeaderWrapper from './HeaderWrapper/HeaderWrapper';
import TabsWrapper from './Tabs/TabsWrapper';
import QuestionsWrapper from './QuestionsWrapper/QuestionsWrapper';
import SearchWrapper from './SearchWrapper/SearchWrapper';
import { AppContext } from './SettingsProvider/SettingsProvider';
import settingsParams from '../settingsParams';
import { CategoriesPosition } from '../../../enums';
import constants from '../../../constants';
import { AccordionCSS } from './QuestionsWrapper/Interfaces';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { DATA_HOOKS } from '../../../constants/dataHooksElements';

export interface ControllerProps {
  instance: string;
  instanceId: string;
  baseURL: string;
  questionId: string;
  api: any;
  initialData: any;
  fromSearch: boolean;
  host?: IHostProps;
  fitToContentHeight: boolean;
}

const Widget: FC<WidgetProps<ControllerProps>> = ({
  instance,
  instanceId,
  baseURL,
  api: appAPI,
  initialData,
  questionId,
  fromSearch,
  host,
}) => {
  const logger = useBi();
  const settings: ISettingsContextValue = useSettings();
  const { isMobile, isEditor, multilingual } = useEnvironment();

  // Handle RTL languages
  let isRTL = settings.get(settingsParams.textAlignment) === 'right';
  if (multilingual?.isEnabled) {
    const languageCode = multilingual.currentLanguage?.split('-')[0] || '';
    isRTL = constants.RTL_LANGUAGE.includes(languageCode);
  }

  // Extract settings values
  const { vertical, horizontal } = settings.get(settingsParams.spacing);
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const showSearch = settings.get(settingsParams.searchBar).show;
  const showFirstAnswer = settings.get(settingsParams.openFirstAnswer);
  const itemAppearance = settings.get(settingsParams.itemAppearance);

  // Define component states
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(
    initialData?.selectedCategory,
  );
  const [firstQuestionId, setFirstQuestionId] = useState<string | undefined>(
    questionId,
  );

  const handleSearchChange = useCallback(
    (term: string) => setSearchTerm(term),
    [],
  );

  // Adjust styles based on settings
  const customStyle: AccordionCSS = {
    '--item-top-bottom-padding': `${vertical}px`,
    '--item-left-right-padding': `${horizontal}px`,
  };

  const showCategories =
    settings.get(settingsParams.displayContent) ===
      constants.DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES || searchTerm !== '';
  const isSideCategories =
    categoriesPosition === constants.DISPLAY_CATEGORIES.SIDE;
  const isTopCategories =
    categoriesPosition === constants.DISPLAY_CATEGORIES.TOP;

  useEffect(() => pauseHeightChanges(), [host]);

  // Log analytics event on load
  useEffect(() => {
    logger.report(
      faqAppLoads({
        num_of_categories_shown: initialData.validLists?.length ?? 0,
        num_of_categories:
          settings.get(settingsParams.manageCategories).categories?.length ?? 0,
        num_of_questions: initialData.questionEntries?.length ?? 0,
        layout_type: settings.get(settingsParams.layout),
        columns: settings.get(settingsParams.structure),
        category_placement: settings.get(settingsParams.categoriesPosition),
      }),
    );
  }, [initialData, logger, settings]);

  // Handle deep linking and scrolling to the question
  useEffect(() => {
    if (isEditor) {
      setFirstQuestionId(showFirstAnswer ? questionId : undefined);
      return;
    }

    if (!questionId || !fromSearch) {
      return;
    }

    const { questionEntries } = initialData;
    const questionData = questionEntries?.find(
      (q: QuestionEntry) => q.id === questionId,
    );

    if (questionData) {
      const { question, categoryId, sortOrder } = questionData;
      logger.report(
        faqQuestionExpanded({
          type: 'deep link',
          question_id: questionId,
          question_name: question || '',
          category_id: categoryId || '',
          question_order: sortOrder || 0,
          url: baseURL || '',
        }),
      );
    }

    const element = document.getElementById(questionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.focus();
    }
  }, [
    fromSearch,
    isEditor,
    questionId,
    showFirstAnswer,
    initialData,
    logger,
    baseURL,
  ]);

  return (
    <TPAComponentsProvider value={{ rtl: isRTL, mobile: isMobile }}>
      <AppContext.Provider
        value={{
          instance,
          instanceId,
          baseURL,
          selectedCategory,
          setSelectedCategory,
          firstQuestionId,
          setFirstQuestionId,
          questionId,
          appAPI,
          searchTerm,
          setSearchTerm,
        }}
      >
        <div
          data-hook={DATA_HOOKS.WIDGET_ROOT}
          dir={isRTL ? 'rtl' : 'ltr'}
          style={customStyle}
          className={st(classes.container, {
            mobile: isMobile,
            isSideCategoriesPosition:
              searchTerm !== '' ? false : isSideCategories,
            isTopCategoriesPosition: isTopCategories || searchTerm !== '',
            hideSearch: !showSearch,
            hideCategories: !showCategories,
            theme: itemAppearance,
          })}
        >
          <SearchWrapper onSearchChange={handleSearchChange} />
          <HeaderWrapper />
          <TabsWrapper validLists={initialData.validLists} />
          <QuestionsWrapper questions={initialData.questionEntries} />
        </div>
      </AppContext.Provider>
    </TPAComponentsProvider>
  );
};

export default Widget;
