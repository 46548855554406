import { HttpClient, HttpResponse } from '@wix/yoshi-flow-editor';
import { queryQuestionEntries } from '@wix/ambassador-faq-question-entry-v2-question-entry/http';
import { listCategories } from '@wix/ambassador-faq-category-v2-category/http';
import type { ListCategoriesResponse } from '@wix/ambassador-faq-category-v2-category/types';
import {
  QueryQuestionEntriesRequest,
  QuestionEntry,
  SortOrder,
} from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

export declare type MultilingualLanguage = {
  isPrimaryLanguage: boolean;
  locale: string;
  languageCode: string;
};
export declare type MultilingualContext<T extends boolean = boolean> = {
  isEnabled: T;
  currentLanguage: T extends true ? string : null;
  siteLanguages: MultilingualLanguage[];
};
export class FAQService {
  faqService: HttpClient;
  constructor(
    instance: string = '',
    instanceId: string = '',
    multilingual: MultilingualContext,
  ) {
    const headers = this.buildHeaders(instance, instanceId, multilingual);

    this.faqService = new HttpClient({
      baseURL: undefined,
      headers,
    });
  }

  private buildHeaders(
    instance: string,
    instanceId: string,
    multilingual: MultilingualContext,
  ): { [key: string]: string } {
    const headers: { [key: string]: string } = {
      Authorization: instance,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    const getLanguageInfo = (
      siteLanguages: MultilingualLanguage[],
      lang: string,
    ) => {
      return siteLanguages?.find(
        (sl: MultilingualLanguage) => sl.languageCode === lang,
      );
    };

    const { currentLanguage, isEnabled, siteLanguages } = multilingual || {};
    const langInfo = getLanguageInfo(siteLanguages, currentLanguage || '');

    if (isEnabled) {
      const headerLang = langInfo
        ? `${currentLanguage}|${langInfo.locale}|${langInfo.isPrimaryLanguage}|${instanceId}`
        : '';
      const [, , isPrimaryLanguage] = headerLang.split('|');

      if (isPrimaryLanguage === 'false') {
        headers['x-wix-linguist'] = headerLang;
      }
    }

    return headers;
  }

  getListCategories = async () => {
    const response: HttpResponse<ListCategoriesResponse> =
      await this.faqService.request(listCategories({}));

    while (response.data.pagingMetadata?.hasNext) {
      const nextCursor = response.data.pagingMetadata.cursors?.next;

      const nextResponse = await this.faqService.request(
        listCategories({
          paging: nextCursor ? { cursor: nextCursor } : undefined,
        }),
      );

      response.data.categories!.push(...nextResponse.data.categories!);
      response.data.pagingMetadata = nextResponse.data.pagingMetadata;
    }

    return response.data;
  };

  getListQuestions = async ({
    categoryId,
    cursor,
    limit = 50,
  }: {
    categoryId?: string | null | undefined | (string | null | undefined)[];
    limit?: number;
    cursor?: string;
  }) => {
    const requestPayload: QueryQuestionEntriesRequest = {
      query: {
        cursorPaging: { cursor, limit },
      },
    };

    if (!cursor && requestPayload.query) {
      requestPayload.query.filter = { categoryId };
      requestPayload.query.sort = [
        { fieldName: 'sortOrder', order: SortOrder.ASC },
      ];
    }

    const response = await this.faqService.request(
      queryQuestionEntries(requestPayload),
    );
    const getListQuestionsResponse = response.data;
    let allCategoriesInResponse = categoryId;
    allCategoriesInResponse = this.getCategoriesFromQuestionsResponse(
      categoryId,
      getListQuestionsResponse.questionEntries,
    );
    getListQuestionsResponse.questionEntries = this.sortEntriesByCategories(
      allCategoriesInResponse,
      getListQuestionsResponse.questionEntries,
    );
    return getListQuestionsResponse;
  };

  getQuestion = async ({
    id,
  }: {
    id?: string | null | undefined | (string | null | undefined)[];
  }) => {
    const response = await this.faqService.request(
      queryQuestionEntries({
        query: {
          filter: { id },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    );
    return response.data;
  };

  getSearchTerm = async ({
    term,
    cursor,
  }: {
    term: string;
    cursor?: string;
  }) => {
    term = '%' + `${term}`.trim().replaceAll(' ', '%');

    let requestPayload: QueryQuestionEntriesRequest;

    if (!cursor) {
      // on initial load
      requestPayload = {
        query: {
          filter: {
            $or: [
              {
                draftjs: {
                  $startsWith: term,
                },
              },
              {
                question: {
                  $startsWith: term,
                },
              },
            ],
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      };
    } else {
      // on load more
      requestPayload = {
        query: {
          cursorPaging: { cursor },
        },
      };
    }

    const response = await this.faqService.request(
      queryQuestionEntries(requestPayload),
    );

    return {
      questionEntries: response.data?.questionEntries || [],
      pagingMetadata: response.data?.pagingMetadata || {},
    };
  };

  private sortEntriesByCategories(
    allCategoriesInResponse:
      | string
      | (string | null | undefined)[]
      | null
      | undefined,
    questionEntries: QuestionEntry[] | undefined,
  ) {
    let sortedEntries: QuestionEntry[] | undefined = questionEntries;
    if (Array.isArray(allCategoriesInResponse) && questionEntries?.length) {
      sortedEntries = [];
      for (const id of allCategoriesInResponse) {
        const entries = questionEntries.filter(
          (entry: QuestionEntry) => entry.categoryId === id,
        );
        sortedEntries = [...sortedEntries, ...entries];
      }
    }
    return sortedEntries;
  }

  private getCategoriesFromQuestionsResponse(
    categoryId: string | (string | null | undefined)[] | null | undefined,
    questionEntries: QuestionEntry[] | undefined,
  ) {
    let allCategoriesInResponse = categoryId;
    if (!Array.isArray(categoryId) && typeof categoryId !== 'string') {
      if (questionEntries?.length) {
        const allCategories: string[] = [];
        questionEntries.forEach(({ categoryId: questionCategoryId }) => {
          if (typeof questionCategoryId === 'string') {
            if (allCategories.includes(questionCategoryId)) {
              allCategories.push(questionCategoryId);
            }
          }
        });
        allCategoriesInResponse = allCategories;
      }
    }
    return allCategoriesInResponse;
  }
}
